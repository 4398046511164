<template>
	<div class="mendian-page-main">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	data() {
		return {
			account: {
				mobile: '',
				user_name: '',
				face: '',
			},
			siteInfo: null,
		}
	},
	computed: {
		getNowYear() {
			let date = new Date();
			return date.getFullYear();
		}
	},
	created() {
		this.getSiteInfo();
		this.getLayoutData();

	},
	methods: {
		getSiteInfo() {
			this.$http.api('platform/siteInfo').then(res => {
				this.siteInfo = res.site;
			}).catch(res => {
				console.log(res);
			});
		},
		getLayoutData() {
			this.$http.api('platform/account/layout').then(res => {
				this.account = res.account;
			}).catch(res => {
				console.log(res);
			})
		}
	}
}
</script>

<style>
.mendian-footer {
	background-color: #eaa53a;
	height: 40px;
	color: white;
	padding: 10px;
}

.mendian-list {
	display: grid;
	grid-template-columns: repeat(3, 380px);
	grid-template-rows: repeat(3, 100px);
	grid-column-gap: 30px;
	grid-row-gap: 30px;
}

.mendian-item {
	width: 380px;
	height: 100px;
	background: #FFFFFF;
	border-radius: 8px;
	border-left: 8px solid #EBEDF5;
	padding: 24px 16px;
	cursor: pointer;
	transition: all 0.3s;
}

.mendian-item .tag {
	width: 52px;
	height: 20px;
	border-radius: 10px;
	border: 1px solid #4772FF;
	text-align: center;
	line-height: 18px;
	font-size: 12px;
	color: #4772FF;
}

.mendian-item:hover {
	transform: translateY(-10px);
}

.mendian-item.add {
	border: 1px dashed #4772FF;
}

.mendian-page {
	min-width: 1200px;
	min-height: 100vh;
	display: flex;
	flex: auto;
	flex-direction: column;
}

.mendian-page-main {
	margin-top: 10px;
}
</style>